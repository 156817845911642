<template>
  <div>
    <v-row>
      <v-col>
        <v-toolbar flat>
          <v-col cols="4" md="3">
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="pt-5"
                  v-model="dateRangeText"
                  label="Intervalo de fechas"
                  persistent-hint
                  readonly
                  append-icon="mdi-calendar-multiple"
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  click:clear="dates=''"
                ></v-text-field>
              </template>
              <v-date-picker
                range
                v-model="dates"
                v-on:change="periodo = ''"
                no-title
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="2" md="2">
            <v-autocomplete
              v-model="periodo"
              class="pt-5"
              label="Mes contable"
              :items="periodos"
              item-value="custom_time_period_id"
              item-text="period_name"
              clearable
              click:clear="accountingMonth =''"
            ></v-autocomplete>
          </v-col>

          <v-col col="4">
            <v-btn small color="primary" @click="generarReporte">
              <v-icon>mdi-magnify</v-icon> Buscar
            </v-btn>
            <v-btn small color="primary" @click="descargarReporte" class="ml-2">
              <v-icon>mdi-file-excel</v-icon> Descargar
            </v-btn>
          </v-col>
        </v-toolbar>
        <v-toolbar color="#cecece" flat dense class="mt-4"
          ><v-toolbar-title>ACTIVOS</v-toolbar-title>
        </v-toolbar>
        <v-data-table
          :headers="headers"
          :items="activos"
          :items-per-page="1000"
          single-expand
          :expanded.sync="expanded"
          dense
          item-key="name"
          :show-expand="false"
          :loading="loadingTable"
          :hide-default-footer="true"
          class="elevation-1 pt-4 px-2"
        >
          <template v-slot:footer>
            <v-row>
              <v-col class="col-md-10 text-right">
                <strong> Total:</strong>
              </v-col>
              <v-col class="col-md-2 text-right pr-7">
                <strong>{{ totalActivos.toFixed(2) }}</strong>
              </v-col>
            </v-row>
          </template>
        </v-data-table>

        <v-toolbar color="#cecece" flat dense class="mt-4"
          ><v-toolbar-title>PASIVOS</v-toolbar-title>
        </v-toolbar>
        <v-data-table
          :headers="headers"
          :items="pasivos"
          :items-per-page="1000"
          single-expand
          :expanded.sync="expanded"
          dense
          item-key="name"
          :show-expand="false"
          :loading="loadingTable"
          :hide-default-footer="true"
          class="elevation-1 px-2"
        >
          <template v-slot:footer>
            <v-row>
              <v-col class="col-md-10 text-right">
                <strong> Total:</strong>
              </v-col>
              <v-col class="col-md-2 text-right pr-7">
                <strong>{{ totalPasivos.toFixed(2) }}</strong>
              </v-col>
            </v-row>
          </template>
        </v-data-table>

        <v-toolbar color="#cecece" flat dense class="mt-4"
          ><v-toolbar-title>PATRIMONIO</v-toolbar-title>
        </v-toolbar>
        <v-data-table
          :headers="headers"
          :items="patrimonio"
          :items-per-page="1000"
          single-expand
          :expanded.sync="expanded"
          dense
          item-key="name"
          :show-expand="false"
          :loading="loadingTable"
          :hide-default-footer="true"
          class="elevation-1 px-2"
        >
          <template v-slot:footer>
            <v-row>
              <v-col class="col-md-10 text-right">
                <strong> Total:</strong>
              </v-col>
              <v-col class="col-md-2 text-right pr-7">
                <strong>{{ totalCapital.toFixed(2) }}</strong>
              </v-col>
            </v-row>
          </template>
        </v-data-table>

        <v-toolbar>
          <v-col>Ingresos: {{ ingresos }}</v-col>
          <v-col>Gastos: {{ gastos }}</v-col>
          <v-col>Ingreso Neto: {{ ingresoNeto }}</v-col>
        </v-toolbar>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "BalanceMensual",
  data: () => ({
    expanded: [],
    activos: [],
    pasivos: [],
    options: {},
    dates: [],
    periodos: [],
    //accountingMonths:[],
    //accountingAccounts:[],
    headers: [
      { text: "Codigo cuenta", value: "codigo_cuenta" },
      { text: "Nombre cuenta", value: "nombre_cuenta" },
      { text: "Débito", value: "monto_debe", align: "right" },
      { text: "Crédito", value: "monto_credito", align: "right" },
      { text: "Saldo", value: "saldo", align: "right" },
    ],
    anio: "",
    required: [(v) => !!v || "El campo es requerido"],
    requiredSearch: [(v) => !!v || "El campo es requerido"],
    hostname: window.location.protocol + "//" + window.location.hostname,
    partyInternalOrganization: "",

    singleExpand: false,

    periodo: "",
  }),
  computed: {
    ...mapState("master", ["loadingTable", "url", "months", "urlExternal"]),

    dateRangeText() {
      //console.log(this.dates)
      return this.dates.join(" ~ ");
      //return this.dates
    },
    totalPasivos() {
      let total = 0;
      const sum = this.pasivos.reduce((accumulator, currentValue) => {
        return (total += currentValue["saldo"]);
      }, 0);

      return sum;
    },

    totalActivos() {
      let total = 0;
      const sum = this.activos.reduce((accumulator, currentValue) => {
        return (total += currentValue["saldo"]);
      }, 0);

      return sum;
    },

    totalCapital() {
      let total = 0;
      const sum = this.capital.reduce((accumulator, currentValue) => {
        return (total += currentValue["saldo"]);
      }, 0);
      return sum;
    },
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setLoadingTable",
      "setLoadingBtn",
      "setTitleToolbar",
    ]),

    ...mapActions("master", [
      "errorRequest",
      "requestApi",
      "alertNotification",
    ]),

    generarReporte() {
      this.setUrl("reportes");
      this.setLoadingTable(true);
      this.requestApi({
        method: "POST",
        data: {
          accion: "balanceMensual",
          desde: this.dates[0],
          hasta: this.dates[1],
          periodo: this.periodo,
        },
      })
        .then((res) => {
          this.activos = res.data.detail.activos;
          this.pasivos = res.data.detail.pasivos;
          this.capital = res.data.detail.capital;

          this.ingresoNeto = res.data.detail.resultados.ingresoNeto;
          this.ingresos = res.data.detail.resultados.ingresos;
          this.gastos = res.data.detail.resultados.gastos;

          this.setLoadingTable(false);
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    cargarPeriodos() {
      this.anio = new Date().getFullYear();
      this.setUrl("periodo-contable/" + this.anio + "/meses-anio");
      //this.setLoadingTable(true)
      this.requestApi({
        method: "GET",
        data: {},
      })
        .then((res) => {
          this.periodos = res.data.detail;
        })
        .catch(() => {})
        .then(() => {});
    },

    descargarReporte() {
      this.setUrl("reportes");
      this.setLoadingTable(true);
      this.requestApi({
        method: "POST",
        data: {
          accion: "exportarBalanceMensual",
          desde: this.dates[0],
          hasta: this.dates[1],
          periodo: this.periodo,
          exportar: "true",
        },
      })
        .then((res) => {
          console.log(res);
          this.setLoadingTable(false);
          let link = document.createElement("a");

          link.href =
            "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
            res.data.detail.datos;
          link.download = res.data.detail.archivo;
          link.click();
        })
        .catch((err) => {})
        .then(() => {
          this.overlay = false;
          this.setLoadingTable(false);
        });
    },
  },

  mounted() {
    this.generarReporte();

    this.cargarPeriodos();
    this.setTitleToolbar("BALANCE MENSUAL");
  },
};
</script>
